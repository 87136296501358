import * as React from 'react'

const Experiences = ({data}) => {
    const years = Object.keys(data).sort().reverse()

    const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]

    const formatMonth = (str) => str.split("-")[0].toLowerCase().trim()
    const getRankOfMonth = (month) =>{
        return months.indexOf(month)
    }
    const sortable = (a) => getRankOfMonth(formatMonth(a.month))
    const sortByMonths = (arr) => {
        arr.sort((a, b) => 
            (a.month === null)-(b.month===null) || +((sortable(a))>sortable(b)) || -((sortable(a))<sortable(b))
        )
        return arr
    }


    const experience = (item) => {
        return <div className="about_experience_content">
            <p className="coucou">{item.month}</p>
            <p className="baseP">{item.description}</p>
        </div>
    }

    return(
        <div className="about_experience_wrapper">

                    {
                        years.map(year => <div>
                           <h2>{year}</h2>
                           <div>
                                {
                                    sortByMonths(data[year]).map(item => experience(item))
                                }
                           </div>
                        
                        </div>
                        )
                    }
                </div>
    )
}

export default Experiences