import { useStaticQuery, graphql} from 'gatsby'
import * as React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import ExperiencesList from "./Experiences"
import "./About.css"

const About = () => {
    const data = useStaticQuery(
        graphql`
        query {
            about : allMarkdownRemark(filter: {frontmatter: {bio: {regex: "/a/"}}}) {
            edges {
                node {
                id
                frontmatter {
                    bio
                    profileImage {
                        childImageSharp {
                          gatsbyImageData(width:600)
                        }
                      }
                }
                }
            }
            }, 
            experiences :  allMarkdownRemark(filter: {frontmatter: {description: {regex: "/a|e|o|u/"}}}) {
                edges {
                    node {
                    id
                    frontmatter {
                        year
                        description
                        month
                    }
                    }
                }
                }
        }`
    )

    const {profileImage, bio} = data.about.edges[0].node.frontmatter
    const formattedExperiences = data.experiences.edges.map(edge => edge.node.frontmatter)
    const years = [...new Set(formattedExperiences.map(xp => xp.year))].sort().reverse()

    
    const sortByYear = () => {
        const sortedByYear = {}
        years.forEach(year => sortedByYear[year] = formattedExperiences.filter(item => item.year === year))
        return sortedByYear
    }

    return(
        <div className="container">
            <div style={{flex:1}}></div>
            <div style={{ margin:24, flex:1}}>
                <GatsbyImage image={profileImage.childImageSharp.gatsbyImageData} alt="portrait of olga in her atelier holding sculptures"/>
            <p className="baseP" style={{marginTop: 24}}>{bio}</p>
            </div>
            <div style={{flex:2,  height:"75%", marginLeft:24}}>
                <h1>Exhibitions and collaborations</h1>
                <ExperiencesList data={sortByYear()}/>
            </div>
        </div>
        )
}


export default About