import * as React from "react";
import './CategoryItem.css'

const CategoryItem = ({title, selected}) => {
    const [currCategory, setcurrCategory] = selected
    const isCurr = (name) => name === currCategory ? true : false

    const onCategoryClick = (e) => {
        setcurrCategory(e.target.innerHTML)
      }

    return (
        <div className={isCurr(title) ? 'selected catTitle' : 'catTitle'}>
            <h1 onClick={onCategoryClick} className={isCurr(title) ? 'categoryTitle selected' : 'categoryTitle'}>{title}</h1>
        </div>
    )
}

export default CategoryItem