import * as React from 'react'
import {  StaticImage } from "gatsby-plugin-image";
import './Logo.css'
const Logo = ({setAboutIsVisible}) => {
    return (
    <div onClick={() => setAboutIsVisible(prev => !prev)} id="logo">
        <StaticImage src="../img/staticLogo.png" width={150} alt="typographic logo"/>
    </div>
    )
}

export default Logo