import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { graphql} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import CategoryItem from "../componentsV2/CategoryItem";
import IntersectionObserver from "../componentsV2/ImgObserverWrapper"
import "./style.css"
import Logo from "../componentsV2/Logo";
import About from "../componentsV2/About"

const IndexPage = ({data}) => {
  const [currCategory, setcurrCategory] = useState("Objects At Rest")
  const [currProject, setCurrProject] = useState("Oasis")
  const [userHasScrolled, setUserHasScrolled] = useState(false)
  const [aboutIsVisible, setAboutIsVisible] = useState(false)

  const sortByRank = (arr) => arr.sort((a, b) => (a.rank===null)-(b.rank===null) || +(a.rank>b.rank)||-(a.rank<b.rank))

  const imageContainer = useRef(null);

  const images = data.allMarkdownRemark.edges
  .filter(e => e.node.frontmatter.image)
  .map(e => { 
    return {
      project : e.node.frontmatter.project, 
      image : e.node.frontmatter.image?.childImageSharp.gatsbyImageData, 
      rank : e.node.frontmatter.rank 
    }
  } 
  )

  const projects = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter).filter(obj => obj.projectDescription)
  const projectsStructured = projects.map(project => {return {name : project.projectName, description: project.projectDescription, category : project.categoryName, rank: project.project_rank, credits:project.project_credit }})
  const categories = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.name_of_category).map(categoryEdge => {
    return {
      name : categoryEdge.node.frontmatter.name_of_category,
      rank : categoryEdge.node.frontmatter.rank_of_category,
    }
  })

  const structuredData = {}
  sortByRank(categories).forEach(category => {
    structuredData[category.name] = sortByRank(projectsStructured.filter(project => project.category === category.name)).map(project => project.name)
  })

  // console.log("projects", projectsStructured)
  // console.log(structuredData)

  const getCurrProject = () => projectsStructured.filter(project => project.name === currProject)[0]


  useEffect(() => {
    if(!imageContainer.current) return
    setUserHasScrolled(false);
    setCurrProject(structuredData[currCategory][0])
    imageContainer.current.scrollTop = 0;
    imageContainer.current.addEventListener('scroll', onImageContainerScroll)
  }, [currCategory])


  const onImageContainerScroll = () => {
    setUserHasScrolled(true) // avoids calling observer callback when it is set
    imageContainer.current.removeEventListener('scroll', onImageContainerScroll) 
  }

  return (
    <>
      <main id="mainContainer">
        <title>Home Page</title>
        <Logo setAboutIsVisible={setAboutIsVisible}/>
        <div className={`about_container ${aboutIsVisible ? "" : "about_container_hidden"}`}>
          <About/>
        </div>
        <div className="flexCentered flex1 contentContainer" >
          <div className="colLayoutDesktop"></div>
          <div className="flex2 categoryList" >
            <div className="categoryListContainer">
              {
                categories.map(category => <CategoryItem key={`category_${category.name}`} title={category.name} selected={[currCategory, setcurrCategory]}/>)
              }
            </div>
          </div>
          <div className="projectText">
            <div className="projectTitle">
              <h3>{currProject}</h3>
            </div>
            <p className="baseP" >{getCurrProject()?.description}</p>
            <p className="credits">{getCurrProject()?.credits}</p>
          </div>
        </div>

        <div className="imagesContainer" ref={imageContainer}> 
          {
            structuredData[currCategory].map((projectName, i) => {
              const projectImages = images.filter(image => image.project === projectName)
              return <IntersectionObserver key={`wrapper_${projectName}`} userHasScrolled={userHasScrolled} setCurrProject={setCurrProject}>
                <div className="projectImages" id={`${projectName}`}>
                  {
                    sortByRank(projectImages).map((item, index) =>  <GatsbyImage image={item.image} style={{width: "100%"}} alt={`picture of ${item.projectName}`} key={`${item.projectName}${index}`}/>
                  )}
                </div>
              </IntersectionObserver>

            
          })
        }
        </div>
        <a className="email" href="mailto:olganisation.f@gmail.com">Contact</a> 
      </main>
    </>
  );
};

export const pageQuery = graphql`
  query{
    allMarkdownRemark{
      edges {
        node {
          frontmatter {
            project
           
            image {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
            projectDescription
            categoryName
            projectName
            project_rank
            project_credit
            name_of_category
            rank_of_category
          }
        }
      }
    }
  }
  `
export default IndexPage;
