import * as React from 'react'
import { useEffect, useRef } from "react";

const ImgObserverWrapper = ({children,  userHasScrolled, setCurrProject}) => {
    const projectImgContainer = useRef(null);
    
    useEffect(() => {
        if(!projectImgContainer.current) return
        
        const callback = (entries) => {
            if(!userHasScrolled) return 
            const entry = entries[0]

            if(entry.boundingClientRect.top > 0 ) {
                if(entry.isIntersecting) {
                    setCurrProject(entry.target.firstChild.id)
                }
            } 

            if(entry.boundingClientRect.bottom < projectImgContainer.current?.clientHeight) {
                if(entry.isIntersecting) {
                    setCurrProject(entry.target.firstChild.id)
                }

            }
            
        }
        const options = {
            trackVisibility: true,
            delay: 100, 
        }

        const intersectionObserver = new IntersectionObserver(callback, options)
        intersectionObserver.observe(projectImgContainer.current)

    }, [projectImgContainer.current])

    return (
        <div ref={projectImgContainer}>
            {children}
        </div>
    )
    
}


export default ImgObserverWrapper